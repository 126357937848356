export const accordion1Data = {
  accordionTitle: "Edition God Downloads",
  items: [
    { text: "PDF Quran in English", fileName: "quran-in-english.pdf" },
    { text: "PDF Quran English Arabic", fileName: "quran-english-arabic.pdf" },
    { text: "ePub Quran in English", fileName: "quran-in-english.epub" },
    { text: "MP3 Quran in English", fileName: "quran-in-english-mp3.zip" },
    { text: "MP3 Quran in English Verse by Verse", fileName: "quran-in-english-verse-by-verse-mp3.zip" },
    { text: "TXT Quran in English", fileName: "quran-in-english-text.zip" },
  ],
};

export const accordion2Data = {
  accordionTitle: "Edition Allah Downloads",
  items: [
    { text: "PDF Quran in English", fileName: "quran-in-english-allah.pdf" },
    { text: "PDF Quran Arabic English", fileName: "quran-english-arabic-allah.pdf" },
    { text: "ePub Quran in English", fileName: "quran-in-english-allah.epub" },
    { text: "MP3 Quran in English", fileName: "quran-in-english-mp3-allah.zip" },
    { text: "MP3 Quran in English Verse by Verse", fileName: "quran-in-english-verse-by-verse-mp3-allah.zip" },
    { text: "TXT Quran in English", fileName: "quran-in-english-text-allah.zip" },
  ],
};
