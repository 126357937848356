import React from "react";
import AppBarPage from "../components/AppBarPage";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { topAppBarHeight, downloadsBaseUrl } from "../constants/BaseConstants";
import { Link, List, ListItem, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { accordion1Data, accordion2Data } from "../constants/DownloadsData";

const verticalOffset = 40;

const pageTitle = "Download";

const container = {
  maxWidth: "750px",
  margin: "auto",
  marginTop: topAppBarHeight + verticalOffset + "px",
  padding: "0px 14px 0px 14px",
};

const title = {
  fontWeight: "bold",
  margin: "0.8em 0em 0.8em 0em",
  fontSize: "1.1rem",
};

const paragraph = {
  margin: "0.8em 0em 0.8em 0em",
  fontSize: "1rem",
};

const accordions = {
  margin: "1.5em 0 0 0",
};

const link = {
  dosplay: "block",
  textDecoration: "none",
};

function Contact(): JSX.Element {
  return (
    <Box>
      <AppBarPage title={pageTitle} />
      <Box sx={container}>
        <Typography sx={title} variant="h1">
          Download SureQuran Translation
        </Typography>
        <Typography sx={paragraph}>These files are free to use, share, and distribute for non-commercial purposes.</Typography>
        <Typography sx={paragraph}>
          Available in two editions: one edition utilizes &lsquo;God&lsquo; to denote the Creator, while the other edition uses
          &lsquo;Allah&lsquo; to denote the Creator.
        </Typography>
        <Typography sx={paragraph}>Creative Commons Attribution NonCommercial NoDerivatives 4.0 International License.</Typography>
        <Box sx={accordions}>
          {/*Accordion 1 */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>{accordion1Data.accordionTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {accordion1Data.items.map(item => (
                  <ListItem key={item.fileName}>
                    <Link sx={link} href={downloadsBaseUrl + item.fileName} target="_blank" rel="noopener noreferrer">
                      {item.text}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          {/*Accordion 2 */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography>{accordion2Data.accordionTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {accordion2Data.items.map(item => (
                  <ListItem key={item.fileName}>
                    <Link sx={link} href={downloadsBaseUrl + item.fileName} target="_blank" rel="noopener noreferrer">
                      {item.text}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box m={8}></Box>
      </Box>
      <Helmet>
        {/* meta tags */}
        <title>Download Quran: PDF, MP3, ePub, TXT. English and English/Arabic</title>
        <meta
          name="description"
          content="Download Quran: PDF for Mobile, MP3, ePub, TXT, Verse by Verse Audio. English and English with Arabic. Clear, Pure, Precise translation. By Itani & AI."
        />
        <meta name="author" content="Talal Itani" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    </Box>
  );
}

export default Contact;
